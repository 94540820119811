import { useCallback, useState } from 'react';
import i18next from '@/middleware/i18n';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';

type UseIsFormChangedHookOutput = [
  () => void,
];

export const CONSULTATION_FORM_STORAGE_KEY_BASE = 'has_started_consultation_form_at';
export const CONSULTATION_FORM_STORAGE_VALUE = 'true';

export const useFormStatusWithSession = (): UseIsFormChangedHookOutput => {
  const [activatedKeys, setActivatedKeys] = useState<string[]>([]);
  const router = i18next.useRouter();
  const pathname = removeQueryParams(router.asPath);

  const handleFormStatusWithSession = useCallback(() => {
    const activationKey = `${CONSULTATION_FORM_STORAGE_KEY_BASE}:${pathname}`;

    if (activatedKeys.includes(activationKey) || !pathname) {
      return;
    }

    setActivatedKeys((prev) => [...prev, activationKey]);

    try {
      sessionStorage.setItem(activationKey, CONSULTATION_FORM_STORAGE_VALUE);
    } catch { /* empty */ }
  }, [pathname, activatedKeys]);

  return [handleFormStatusWithSession];
};

import { RouterParams } from '@/controllers/router/router.typedefs';
import { ROUTES } from '@/controllers/router/router.contants';

export type TopicLinkFn = (
  courseSlug: string, topicSlug: string, mainRoute: string, section?: string
) => RouterParams;

export const getCourseTopicLinkParams: TopicLinkFn = (courseSlug, topicSlug, mainRoute: string, section = '') => ({
  href: `${mainRoute}/${courseSlug}/${topicSlug}${section
    ? `?section=${section}`
    : ''}`,
});

export const getCourseTopicLink = (
  courseSlug: string,
  topicSlug: string,
  mainRoute: string,
  section: string,
) => `${mainRoute}/${courseSlug}/${topicSlug}?section=${section}`;

export const getConsultationRedirectLink = ({
  courseSlug,
  shouldRedirectToTrialIntro,
  subscriptionPlanUuid,
  shouldRedirectToIntroductionTopic,
  moduleSlug,
  topicSlug,
}: {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  subscriptionPlanUuid?: string;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
}) => {
  if (subscriptionPlanUuid) {
    return `${ROUTES.subscription.subscriptionPay}/${subscriptionPlanUuid}`;
  }

  if (shouldRedirectToTrialIntro) {
    return `${ROUTES.user.learnTopic({
      moduleSlug: 'test-drive',
      topicSlug: 'welcome-to-test-drive',
    })}`;
  }

  if (
    shouldRedirectToIntroductionTopic
    && moduleSlug
    && topicSlug
  ) {
    return `${ROUTES.user.learnTopic({
      moduleSlug,
      topicSlug,
    })}`;
  }

  if (courseSlug) {
    return `${ROUTES.user.learnCourse(courseSlug)}`;
  }

  return `${ROUTES.user.learn}`;
};

import {
  PublishingReviewsByDomainQueryResult,
  PublishingReviewFragment,
  usePublishingReviewsByDomainQuery,
} from '@/controllers/graphql/generated';

interface UsePublishingReviews {
  (subDomain?: string): [
    PublishingReviewFragment[],
    PublishingReviewsByDomainQueryResult
  ];
}

export const usePublishingReviews: UsePublishingReviews = (subDomain) => {
  const query = usePublishingReviewsByDomainQuery({
    variables: {
      domain: subDomain,
    },
  });

  return [
    query.data?.publishingsReviewsByDomain ?? [],
    query,
  ];
};

import React, { memo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { PromoCard } from '@/components/landing/Promotions/components/PromoCard';
import { IconEmojiWrappedGift } from '@/components/ui/IconEmojies/IconEmojiWrappedGift';
import { IconEmojiStarStruck } from '@/components/ui/IconEmojies/IconEmojiStarStruck';
import { LandingPromoCodeSlug, PromoSectionType } from '@/components/landing/Promotions/promotions.typedefs';
import { getConsultationLink, getPromoEventSource } from '@/components/landing/Promotions/promotions.helpers';
import { ELLIPSE_COLOR_ORANGE, ELLIPSE_COLOR_PINK } from '@/components/landing/Promotions/promotions.constants';
import { useGetPromoCodeExpireTime } from '@/components/landing/Promotions/hooks/useGetPromoCodeExpireTime.hook';

interface Props {
  courseSlug?: string;
}

export const PythonPlPresaleStartPromoCard = memo<Props>((props) => {
  const { courseSlug } = props;

  const { t } = useTranslation([I18N_CODES.course]);

  const promoCodeSlug = LandingPromoCodeSlug.PYTHON_PL_PRESALE_START;
  const eventSource = getPromoEventSource({
    promoCodeSlug,
    promoSection: PromoSectionType.CARD,
  });

  const promoCodeExpireAt = useGetPromoCodeExpireTime(promoCodeSlug);

  if (!promoCodeExpireAt) {
    return null;
  }

  return (
    <PromoCard
      promoName={promoCodeSlug}
      source={eventSource}
      TitleIcon={IconEmojiWrappedGift}
      DecorIcon={IconEmojiStarStruck}
      title={t(`${I18N_CODES.course}:promo_code.title.${promoCodeSlug}`)}
      timerEndAt={promoCodeExpireAt}
      description={t(`${I18N_CODES.course}:promo_code.description.${promoCodeSlug}`)}
      buttonText={t(`${I18N_CODES.course}:promo_code.button.${promoCodeSlug}`)}
      ellipseColorLeft={ELLIPSE_COLOR_ORANGE}
      ellipseColorRight={ELLIPSE_COLOR_PINK}
      href={getConsultationLink({
        promoCodeSlug,
        eventSource,
        courseSlug,
      })}
    />
  );
});

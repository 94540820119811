import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { CourseType, PromoCodeStatus } from '@/controllers/graphql/generated';
import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';

interface Options {
  courseType: CourseType;
  promoCode?: PromoCodeLandingBaseFragment;
}

export const useShouldShowCustomerWeekPromo = (
  options: Options,
) => {
  const { courseType, promoCode } = options;
  const { subDomain } = useSubDomainContext();

  const isCorrectDomain = subDomain === SubDomains.br;
  const isPrepaidCourse = courseType === CourseType.Prepaid;
  const isPromoCodeActive = promoCode?.status === PromoCodeStatus.Active;

  return (
    isCorrectDomain
    && isPrepaidCourse
    && isPromoCodeActive
  );
};

import {
  FileBaseFragment,
  StaticFileFragment,
  StaticFilesQueryResult,
  useStaticFilesQuery,
} from '@/controllers/graphql/generated';

type UseStaticFilesOutput = [
  Record<string, FileBaseFragment | null>,
  StaticFilesQueryResult
];

const parseStaticFiles = (
  staticFiles: StaticFileFragment[],
) => staticFiles.reduce((acc: Record<string, FileBaseFragment | null>, cur) => {
  Object.assign(acc, { [cur.name ?? 'unknown']: cur.image });

  return acc;
}, {});

/** @deprecated it loads all static files on every page where called, don't use it */
export const useStaticFilesDeprecated = (): UseStaticFilesOutput => {
  const queryResult = useStaticFilesQuery();

  const { data } = queryResult;

  const content = parseStaticFiles(data?.allStaticFiles || []);

  return [content, queryResult];
};

import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { CourseType, PromoCodeStatus } from '@/controllers/graphql/generated';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';

interface Options {
  courseType: CourseType;
  promoCode?: PromoCodeLandingBaseFragment;
  isMergedLandingEnabled: boolean;
}

export const useShouldShowMonetaryPromo = (
  options: Options,
): boolean => {
  const {
    courseType,
    promoCode,
    isMergedLandingEnabled,
  } = options;

  const { subDomain } = useSubDomainContext();
  const { pageType } = useCtaContext();

  const isCorrectDomain = subDomain === SubDomains.ua;
  const isPrepaidCourse = courseType === CourseType.Prepaid;
  const isCorrectPageType = pageType !== PageType.HOME;
  const isPromoCodeActive = promoCode?.status === PromoCodeStatus.Active;

  return (
    isCorrectDomain
    && (isPrepaidCourse || isMergedLandingEnabled)
    && isCorrectPageType
    && isPromoCodeActive
  );
};

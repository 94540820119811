import React, { FC } from 'react';
import { HeaderCtaButtonContainer } from '@/components/landing/LandingHeader/components/HeaderCtaButtonContainer';
import { Seos } from '@/components/common/Seos';
import { LandingHeader } from '@/components/landing/LandingHeader';
import { HeaderProfileActions } from '@/components/landing/LandingHeader/components/HeaderProfileActions';
import { LandingFooter } from '@/components/landing/LandingFooter';
import {
  SupportSocialMediasCollapsable,
} from '@/components/common/SupportSociaMedias';
import { FlashMessageContainer } from '@/components/ui/flashMessage/FlashMessageContainer';
import { CTAContextProvider } from '@/controllers/cta/Cta.context';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { AnalyticsEvents } from '@/controllers/analytics/analytics.events';
import { HomePageContent } from '@/components/homepage-landing/HomePageContent';
import { LandingSeoTags } from '@/seo/LandingSeoTags';
import { useActivePromo } from '@/components/landing/Promotions/hooks/useActivePromo';
import { CourseType } from '@/controllers/graphql/generated';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';

export const HomePageModule: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);

  const { PromoBanner } = useActivePromo({
    courseType: CourseType.Prepaid,
  });

  return (
    <>
      <Seos />
      <LandingSeoTags />

      <CTAContextProvider
        value={{
          text: t(`${I18N_CODES.common}:study_for_free`),
          href: `#${COURSE_BLOCKS.allCourses}`,
          callback: (data: Record<string, any> = {}) => {
            AnalyticsEvents.sendEvent(
              AnalyticsEvents.events.courseCTAClicked,
              data,
            );
          },
          pageType: PageType.HOME,
        }}
      >
        <LandingHeader>
          <HeaderCtaButtonContainer>
            <HeaderProfileActions />
          </HeaderCtaButtonContainer>
        </LandingHeader>

        <HomePageContent />

        <LandingFooter />

        <SupportSocialMediasCollapsable shouldShowScrollToTop={false} />

        {PromoBanner && (
          <PromoBanner />
        )}

        <FlashMessageContainer />
      </CTAContextProvider>
    </>
  );
};

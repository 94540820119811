import React, {
  FC, useMemo,
} from 'react';
import { ExitPopup } from '@/components/common/ExitPopup';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { Button } from '@/components/ui/Button';
import { useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { useShouldRedirectToConsultationSurvey } from '@/components/user-survey/UserSurvey.hooks/useShouldRedirectToConsultationSurvey.hook';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import chubbsFormulas from '@/images/generated/landing.pageExitPopup.chubbsFormulas.s3Key';
import { IconImage } from '@/components/ui/Image/IconImage';
import styles from './PageExitPopup.module.scss';

interface Props {
  source: string;
}

export const PageExitPopup: FC<Props> = ({ source }) => {
  const [
    isPopupOpened,
    closePopup,
  ] = useIsOpenStateDeprecated({ initialState: true });

  const { t } = useTranslation([I18N_CODES.home]);

  const shouldRedirectToConsultationSurvey = (
    useShouldRedirectToConsultationSurvey()
  );

  const consultationLink = useMemo(() => {
    const preparedSource = `${source}_exit_popup`;

    return shouldRedirectToConsultationSurvey
      ? ROUTES.consultation.signUpSurvey({
        source: preparedSource,
        courseSlug: ConsultationCourses.GeneralConsultation,
      })
      : `${ROUTES.consultation.index}?source=${preparedSource}`;
  }, [shouldRedirectToConsultationSurvey, source]);

  return (
    <ExitPopup
      renderTitle={() => (
        <>
          <div className={styles.imageContainer}>
            <IconImage
              imageData={chubbsFormulas}
              className={styles.image}
              objectFit="contain"
              width={chubbsFormulas.fallback.width}
              height={chubbsFormulas.fallback.height}
              loading="lazy"
            />
          </div>

          <h2 className={styles.popupTitle}>
            {t(`${I18N_CODES.home}:exit-popup.title`)}
          </h2>
        </>
      )}
      renderCloseButton={false}
      isOpened={isPopupOpened}
      className={styles.modalContainer}
    >
      <p className={cn(styles.mainText, 'mb-24')}>
        {t(`${I18N_CODES.home}:exit-popup.text`)}
      </p>

      <div className={styles.buttonsContainer}>
        <Button
          mode={Button.mode.BrandSecondary}
          size={Button.size.Large}
          hasFullWidth
          onClick={closePopup}
          text={t(`${I18N_CODES.home}:exit-popup.secondary-button-text`)}
        />

        <Button
          size={Button.size.Large}
          mode={Button.mode.BrandPrimary}
          hasFullWidth
          href={consultationLink}
          text={t(`${I18N_CODES.home}:exit-popup.primary-button-text`)}
          isInternalLink
        />
      </div>
    </ExitPopup>
  );
};

export enum PageType {
  COURSE = 'course',
  COURSES = 'courses',
  HOME = 'home',
}

export enum LandingPromoCodeSlug {
  WEEKLY_PROMO_PL = 'weekly-promo-pl',
  WEEKLY_PROMO_BR = 'weekly-promo-br',
  CUSTOMER_WEEK_PROMO = 'customer-week-promo',
  FREE_ENGLISH_PROMO = 'free-english-promo',
  ONE_PLUS_ONE_PROMO = 'one-plus-one-promo',
  MONETARY_PROMO_UA = 'monetary-promo-ua',
  UNLIM_PROMO = 'unlim-promo',
  DATA_ANALYTICS_PRESALE_START_UA = 'data-analytics-presale-start-ua',
  DATA_ANALYTICS_PRESALE_END_UA = 'data-analytics-presale-end-ua',
  QA_BR_PRESALE_START = 'qa-br-presale-start',
  QA_BR_PRESALE_END = 'qa-br-presale-end',
  PYTHON_PL_PRESALE_START = 'python-pl-presale-start',
  PYTHON_PL_PRESALE_END = 'python-pl-presale-end',
  KNOWLEDGE_DAY_PROMO = 'knowledge-day-promo',
}

export enum PromoSectionType {
  BANNER = 'banner',
  CARD = 'card',
}

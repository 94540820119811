import React from 'react';
import { QaBrPresalePromoBanner } from '@/components/landing/Promotions/QaBrPresalePromo/QaBrPresalePromoBanner';
import { LandingPromoCodeSlug } from '@/components/landing/Promotions/promotions.typedefs';
import { QaBrPresalePromoCard } from '@/components/landing/Promotions/QaBrPresalePromo/QaBrPresalePromoCard';

export const QaBrPresalePromoStartBanner = () => (
  <QaBrPresalePromoBanner
    promoCodeSlug={LandingPromoCodeSlug.QA_BR_PRESALE_START}
  />
);

export const QaBrPresalePromoEndBanner = () => (
  <QaBrPresalePromoBanner
    promoCodeSlug={LandingPromoCodeSlug.QA_BR_PRESALE_END}
  />
);

export const QaBrPresalePromoStartCard = () => (
  <QaBrPresalePromoCard
    promoCodeSlug={LandingPromoCodeSlug.QA_BR_PRESALE_START}
  />
);

export const QaBrPresalePromoEndCard = () => (
  <QaBrPresalePromoCard
    promoCodeSlug={LandingPromoCodeSlug.QA_BR_PRESALE_END}
  />
);
